import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent {
  customerBase64Signature: string;
  customerColor = localStorage.getItem('cor');

  canvas: HTMLCanvasElement | undefined;
  ctx: CanvasRenderingContext2D;
  drawing = false;
  x = 0;
  y = 0;

  constructor(private modalController: ModalController) {}

  ngAfterViewInit() {
    this.canvas = document.querySelector('canvas');
    this.setupCanvas();
    this.canvasResize();
    window.onresize = () => this.canvasResize();
  }

  preventScroll(event: TouchEvent): void {
    event.preventDefault();
  }

  canvasResize() {
    const canvas = this.canvas;
    const maxWidth = 550;
    const maxHeight = 300;
    const width = window.innerWidth - 0.1 * window.innerWidth;
    const height = window.innerHeight - 0.5 * window.innerHeight;

    if (width >= maxWidth) {
      canvas.width = maxWidth;
    } else {
      canvas.width = width;
    }

    if (height >= maxHeight) {
      canvas.height = maxHeight;
    } else {
      canvas.height = height;
    }
  }

  confirmSignature() {
    const sign = this.canvas.toDataURL();

    console.log(sign);
    return this.modalController.dismiss(sign, 'confirmed');
  }

  closeModal() {
    return this.modalController.dismiss(null, 'cancel');
  }

  drawClear() {
    this.customerBase64Signature = '';
    this.ctx?.clearRect(0, 0, this.canvas?.width || 0, this.canvas?.height || 0);

  }

  ngOnDestroy() {
    window.onresize = null;

    this.canvas.removeEventListener('mousedown', this.startDrawing.bind(this));
    this.canvas.removeEventListener('mousemove', this.draw.bind(this));
    this.canvas.removeEventListener('mouseup', this.stopDrawing.bind(this));
    this.canvas.removeEventListener('mouseout', this.stopDrawing.bind(this));

    this.canvas.removeEventListener('touchstart', this.startDrawing.bind(this));
    this.canvas.removeEventListener('touchmove', this.draw.bind(this));
    this.canvas.removeEventListener('touchend', this.stopDrawing.bind(this));
  }

  setupCanvas() {
    this.ctx = this.canvas.getContext('2d');

    if (this.canvas && this.ctx) {
      this.ctx.lineWidth = 1;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = 'black';

      this.canvas.addEventListener('mousedown', this.startDrawing.bind(this));
      this.canvas.addEventListener('mousemove', this.draw.bind(this));
      this.canvas.addEventListener('mouseup', this.stopDrawing.bind(this));
      this.canvas.addEventListener('mouseout', this.stopDrawing.bind(this));

      this.canvas.addEventListener('touchstart', this.startDrawing.bind(this));
      this.canvas.addEventListener('touchmove', this.draw.bind(this), {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.stopDrawing.bind(this));
    } else {
      console.error('Erro ao preparar canvas de assinatura!');
    }
  }

  startDrawing(event: MouseEvent | TouchEvent) {
    this.drawing = true;
    this.ctx?.beginPath();
    this.setPosition(event);
  }

  draw(event: MouseEvent | TouchEvent) {
    if (!this.drawing || !this.ctx) return;

    event.preventDefault();

    this.setPosition(event);
    this.ctx.lineTo(this.x, this.y);
    this.ctx.stroke();
  }

  setPosition(event: MouseEvent | TouchEvent) {
    const rect = this.canvas.getBoundingClientRect();
    const touch = event instanceof TouchEvent ? event.touches[0] : null;
    this.x = touch
      ? touch.clientX - rect.left
      : (event as MouseEvent).clientX - rect.left;
    this.y = touch
      ? touch.clientY - rect.top
      : (event as MouseEvent).clientY - rect.top;
  }

  stopDrawing() {
    this.drawing = false;
    this.ctx?.closePath();
  }

  clearSignature() {
    this.customerBase64Signature = undefined;
    this.ctx?.clearRect(
      0,
      0,
      this.canvas?.width || 0,
      this.canvas?.height || 0,
    );
  }

  setSignatureValue() {
    this.customerBase64Signature = this.canvas.toDataURL('image/png');
  }
}
