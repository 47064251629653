/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
import { Events } from './Events';
import { UtilService } from './util-service';
import { Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  imei;
  modelo;
  plataforma;
  constructor(
    public http: HttpClient,
    public util: UtilService,
    public events: Events,
  ) {
    this.fetchDeviceInfo();
  }

  async fetchDeviceInfo() {
    this.imei = await this.util.getDeviceID();
  }

  login(request: any): Promise<HttpResponse> {

    const params = {
      username: request.username,
      password: request.password,
      pushtoken: request.pushtoken,
      aparelho: request.aparelho,
    };

    const host = this.util.obterHostPadraoDaApi() + '/login?';
    const urlSearchParams = new URLSearchParams(params);
    const encodedUrl = host + urlSearchParams;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json',
    );

    return this.http.post<HttpResponse>(encodedUrl, { headers }).toPromise();
  }

  //Imagem PreVenda
  salvaImagem(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/vendas/api/imagem';
    let params = JSON.stringify(request);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    //headers.append('Content-Type', 'application/json');
    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  signPreTerm(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi()  + '/contratos/api/app/pre-termo/sign';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    const params = JSON.stringify(request);

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  sendPreTermSelfie(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi()  + '/contratos/api/app/pre-termo/selfie';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, request, { headers: options.headers })
      .toPromise();
  }

  getFaturasSga(request: any): Promise<HttpResponse> {
    let uri = 'integracoes/sga/faturas2';
    let host_t =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host_t = host_t + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host_t = host_t + '&' + 'dados={"token": "' + request.token + '" }';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host_t, options).toPromise();
  }

  getStatusMydas(request: any) {
    let uri = 'clienteBuscaCpfCnpj';
    let host = this.util.obterHostPadraoDaApi() + '/api/manticora_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8891', '');
    host =
      host +
      '&' +
      'dados={"chave": "' +
      request.chave +
      '", "cpf_cnpj":"' +
      request.cpf_cnpj +
      '"}';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getPets(request: any) {
    let uri = 'get_pets';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/manticora_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados={"cpf":"' + request.cpfCnpj + '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getAtendimentosPet(request: any) {
    let uri = 'get_atendimentos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/manticora_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados={"cpf":"' + request.cpfCnpj + '"}';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getMenu(request: any) {
    let uri = 'get_menu';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados={"token": "' + request.token + '" }';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getChatNaoLidas(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/sinistros-eventos/api/app/atendimentos/chat/nao-lidas?id_cliente=';
    host =
      host +
      request.idCliente +
      '&database=' +
      request.database +
      '&format=json';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getChat(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/sinistros-eventos/api/app/atendimentos/chat?id_atendimento=' +
      request.idAtendimento;
    host = host + '&database=' + request.database + '&format=json';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  setaSaude(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/hapoloapp/saude?id_cliente=' +
      request.id_cliente +
      '&database=';
    host = host + request.database + '&format=json';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  visualizaChat(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi();
    host =
      host +
      '/sinistros-eventos/api/app/atendimentos/chat/visualiza?id_atendimento=' +
      request.idAtendimento;
    host = host + '&database=' + request.database + '&format=json';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  insertAtendimento(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/sinistros-eventos/api/app/atendimentos?database=' +
      request.database;
    host = host + '&id_cliente=' + request.idCliente;
    console.log(host);
    let params = JSON.stringify(request);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  insertChat(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/sinistros-eventos/api/app/atendimentos/chat/nova-mensagem';
    let params = JSON.stringify(request);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  createAccount(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() + '/api/clientes/criar_conta_global';
    let params = JSON.stringify(request);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  getAtendimentos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/sinistros-eventos/api/app/atendimentos?id_cliente=' +
      request.idCliente +
      '&database=';
    host = host + request.database + '&format=json';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getIps(request: any) {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/dashboard/ipsecundarios?ip=' +
      request.ip;

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  viewBanner(request: any) {
    let uri = 'visualiza_banner';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "banner": "' +
      request.banner +
      '" }';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getRotas(request: any) {
    let uri = 'listas/rotas';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"hash": "' + request.token + '" }';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getBanners(request: any) {
    let uri = 'listas/banner';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  checkLgpdTerms(request: any) {
    let uri = 'api/lgpd';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  viewLgpdTerms(request: any) {
    let uri = 'api/lgpd';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '"}' +
      '&' +
      'method=' +
      'POST';
    let params: string = JSON.stringify(request);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { params: params as any })
      .toPromise();
  }

  getProdutosIntegrados(request: any) {
    let uri = 'get_produtos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getParceirosCidades(request: any) {
    let host =
      this.util.obterHostFilesDaApi() +
      '/parceiros/api/cidades?id_admin=' +
      request.adminId +
      '&database=';
    host =
      host + request.database + '&estilo=' + request.estilo + '&format=json';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getParceirosCategorias(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() + '/parceiros/api/categorias?id_cliente=';
    host = host + request.adminId + '&database=' + request.database;
    host =
      host +
      '&format=json&limite=100000&offset=0&cidade=' +
      request.cidade +
      '&estilo=' +
      request.estilo +
      '&format=json';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getParceiros(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/parceiros/api/parceiros?id_cliente=' +
      request.adminId;
    host =
      host +
      '&database=' +
      request.database +
      '&format=json&limite=10&offset=' +
      request.offset +
      '&cidade=';
    host =
      host +
      request.cidade +
      '&tipo=' +
      request.tipo +
      '&categoria=' +
      request.categoria;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getInformativos(request: any): Promise<HttpResponse> {
    let uri = 'listas/informativos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getPedidos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/vendas/api?admin_id=' +
      request.admin_id +
      '&database=';
    host = host + request.database + '&cliente_id=' + request.cliente_id;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getCartoes(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/cartoes-credito?id_cliente=' +
      request.django_user_id;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getContratosCliente(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/contratos/api/app/contrato-arquivo/list?id_cliente=';
    host = host + request.idCliente + '&database=' + request.database;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getContratosHrp(request: any): Promise<HttpResponse> {
    let cpf = request.cpf;
    let host =
      'https://hrp.hapolo.com.br/associados-veiculos-termos-api/' + cpf;
    //For testing
    //host = 'https://hrp.hapolo.com.br/associados-veiculos-termos-api/18000502089'

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getContratosTotal(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/contratos/api/app/contrato-arquivo/count?id_cliente=';
    host = host + request.idCliente + '&database=' + request.database;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  getContratosMydas(cpf: string) {

    const host = this.util.obterHostPadraoDaApi() + '/api/manticora_ponte' + '?';

    const queryParams = `uri=get_contratos&timeout=15&dados={"cpf":"${cpf}"}`;

    return this.http.get(host + queryParams).toPromise()
  }

  getDependentes(request: any): Promise<HttpResponse> {
    let uri = 'get_dependentes';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getSgaVistoria(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/webview/searchimage?id_cliente=' +
      request.idCliente +
      '&empresa=' +
      request.database;
    host = host + '&sga_id=' + request.sgaId;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  marcaTermos(request: any): Promise<HttpResponse> {
    let uri = 'status_termos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "aceito": "' +
      request.aceito +
      '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  loginSga(request: any): Promise<HttpResponse> {
    let uri = 'login_sga';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri;
    host = host + '&' + 'ip=' + request.ip + '&method=' + request.method;
    host =
      host +
      '&' +
      'dados=' +
      '{"cpf": "' +
      request.cpf +
      '" , "id_admin": "' +
      request.idAdmin +
      '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getAtendimentos24(request: any): Promise<HttpResponse> {
    let uri = 'listas/contatos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getEventos(request: any): Promise<HttpResponse> {
    let uri = 'listas/eventos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getTermos(request: any): Promise<HttpResponse> {
    let uri = 'termos_app';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host = host + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getSobreEmpresa(request: any): Promise<HttpResponse> {
    let uri = 'get_empresa';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=';
    host =
      host +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados={"token": "' + request.token + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  sendSgaVistoria(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/webview/vinculaimagem';
    let params = JSON.stringify(request);
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  getNotificacoesFaturamento(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/vendas/api/notificacao?id_cliente=' +
      request.id_cliente;
    host =
      host +
      '&database=' +
      request.database +
      '&empresa=' +
      request.database +
      '&tipo_envio=whatsapp';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  sendDataToChangePassword(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/hapoloapp/esqueci' +
      '?cpf=' +
      request.cpf;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  newCartao(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/api/cartoes-credito';
    let params = JSON.stringify(request);
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  deleteCartao(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/api/cartoes-credito';
    let params = JSON.stringify(request);
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  alteraEmail(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/clientes/api/change_dados?apelido=' +
      request.apelido;
    host =
      host + '&id_cliente=' + request.idCliente + '&email=' + request.email;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  alteraAssociado(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() + '/api/clientes/api/change_dados';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    let params = request;

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  alteraAtendimentos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/clientes/api/change_dados_atendimentos';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    let params = request;

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  alteraEventos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/clientes/api/change_dados_eventos';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    let params = request;

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  getJson(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/api/clientes/api/array';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http
      .post<HttpResponse>(host, request, { headers: options.headers })
      .toPromise();
  }

  alteraBoletos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/clientes/api/change_dados_boletos';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    let params = request;

    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  getCustos(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() +
      '/api/clientes/getcusto-global?db=' +
      request.db +
      '&admin=' +
      request.admin;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  setaCartao(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostFilesDaApi() + '/api/cartoes-credito/set-principal';
    let params = JSON.stringify(request);
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http
      .post<HttpResponse>(host, params, { headers: options.headers })
      .toPromise();
  }

  getCepDados(request: any): Promise<HttpResponse> {
    let host =
      'https://apps.widenet.com.br/busca-cep/api/cep/' + request.cep + '.json';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .get<HttpResponse>(host, { headers: options.headers })
      .toPromise();
  }

  listaImagens(request: any): Promise<HttpResponse> {
    let uri = 'get_imagens';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados={"token": "' +
      request.hash +
      '" , "id_pre": "' +
      request.id_pre +
      '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //Endereco do google tela veiculo sozinho
  getLocalGoogle(request: any): Promise<HttpResponse> {
    let host = request.url;
    let options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    };
    //headers.append('Content-Type', 'application/json');
    return this.http
      .post<HttpResponse>(host, request, { headers: options.headers })
      .toPromise();
  }

  //alterar senha
  alterarSenha(request: any): Promise<HttpResponse> {
    try {
      let uri = 'alterar_perfil';
      let host =
        this.util.obterHostPadraoDaApi() +
        '/api/apis_aplicativo_ponte' +
        '?' +
        'uri=' +
        uri +
        '&' +
        'ip=';
      host =
        host + this.util.obterHostDaApi().replace(':8890', '') + '&method=POST';

      //let host = this.util.obterHostDaApi() + 'alterar_perfil';

      let telefone = request.telefone;

      host =
        host +
        '&' +
        'dados=' +
        '{"token": "' +
        request.token +
        '", "telefone": "' +
        telefone +
        '", "nome": "';
      host =
        host +
        request.nome +
        '", "antiga_senha": "' +
        request.antiga_senha +
        '", "nova_senha": "' +
        request.nova_senha +
        '"}';

      let options = {
        headers: new HttpHeaders().append(
          'Content-Type',
          'application/x-www-form-urlencoded;',
        ),
      };

      return this.http.get<HttpResponse>(host, options).toPromise();
    } catch (error) {
      alert(error);
    }
  }

  //criar uma conta de adm
  criarConta(request: any): Promise<HttpResponse> {
    let host =
      this.util.obterHostPadraoDaApi().replace('/api_app', '') +
      '/create_admin';
    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http
      .post<HttpResponse>(host, request, { headers: options.headers })
      .toPromise();
  }

  listaArquivos(request: any): Promise<HttpResponse> {
    let uri = 'listas/arquivos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.hash + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //criar uma conta de adm
  esqueci(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostFilesDaApi() + '/api/cliente/trocarsenha';
    host = host + '?cpf=' + request.cpf;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //criar uma conta de adm
  tutorial() {
    let host = this.util.obterHostPadraoDaApi() + '/listas/tutoriais';
    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  valida(request: any): Promise<HttpResponse> {
    let uri = 'login';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.hash + '" }';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  logoutUser(): any {
    let request = { hash: localStorage.getItem('hash') };
    this.logout(request)
      .then((response) => {
        localStorage.clear();
        localStorage.removeItem('dados_cliente');
        localStorage.removeItem('dados_empresa');
        localStorage.removeItem('hash');
        localStorage.removeItem('id_cliente');
        localStorage.removeItem('success');
        localStorage.removeItem('url_padrao');
        localStorage.removeItem('url_eventos_sga');

        setTimeout(() => {
          let user = JSON.parse(localStorage.getItem('dados_cliente'));
          this.events.publish('user:menu', { user: user });
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //logout
  logout(request: any): Promise<HttpResponse> {
    let uri = 'logout';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"has": "' + request.hash + '" }';
    //let host = this.util.obterHostDaApi() + 'logout';
    //host = host + '?hash=' + request.hash;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //criar uma conta de adm
  listaDependente(request: any): Promise<HttpResponse> {
    let uri = 'listas/dependentes';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"hash": "' + request.token + '" }';
    //host = host + '?hash=' + request.token;
    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //criar uma conta de adm
  criarDependente(request: any): Promise<HttpResponse> {
    //let host = this.util.obterHostDaApi() + 'create_dependente';
    let uri = 'create_dependente';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host =
      host +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    if (request.id != null) {
      host =
        host +
        '&' +
        'dados={"token": "' +
        request.token +
        '", "nome": "' +
        request.nome +
        '", "emai":"' +
        request.email +
        '", "senha": "' +
        request.senha +
        '", "usuario": "' +
        request.usuario +
        '", "veiculos": "' +
        request.veiculos +
        '", "id": "' +
        request.id +
        '" }';
    } else {
      host =
        host +
        '&' +
        'dados={"token": "' +
        request.token +
        '" , "nome": "' +
        request.nome +
        '", "email":"' +
        request.email +
        '", "senha": "' +
        request.senha +
        '", "usuario": "' +
        request.usuario +
        '", "veiculos": "' +
        request.veiculos +
        '" }';
    }

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getInfoFipe(request: any): Promise<HttpResponse> {
    return this.http
      .get<HttpResponse>(
        `https://web.hapolo.com.br/api/get_dados_by_placa?placa=${request.placa}&tipo=${request.tipo}`,
      )
      .toPromise();
  }

  async getMeuslocais(request: any): Promise<HttpResponse> {
    let uri = 'listas/locais';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host = host + 'ip=' + this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    //let host = this.util.obterHostDaApi() + 'listas/locais';
    //host = host + '?token=' + request.token;

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return await this.http.get<HttpResponse>(host, options).toPromise();
  }

  insereLocal(request: any): Promise<HttpResponse> {
    let uri = 'add_local';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&';
    host =
      host +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "nome": "' +
      request.nome +
      '", "latitude": "' +
      request.latitude +
      '", "longitude": "' +
      request.longitude +
      '", "imei":"' +
      request.imei +
      '"}';
    //let host = this.util.obterHostDaApi() + 'add_local';
    //host = host + '?token=' + request.token + '&nome=' +request.nome+ '&latitude=' +request.latitude+ '&longitude=' +request.longitude
    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }
  async deleteLocal(request: any): Promise<HttpResponse> {
    let uri = 'delete_local';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=';
    host =
      host + this.util.obterHostDaApi().replace(':8890', '') + '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "id": "' +
      request.id +
      '"}';
    //let host = this.util.obterHostDaApi() + 'delete_local';
    //host = host + '?token=' + request.token + '&id=' +request.id;;

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return await this.http.get<HttpResponse>(host, options).toPromise();
  }

  getAparelhos(request: any): Promise<HttpResponse> {
    let uri = 'listas/push';
    let host = this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte';
    host =
      host +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    //let host = this.util.obterHostDaApi() + 'listas/push';
    //host = host + '?token=' + request.token;

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  edit_push(request: any): Promise<HttpResponse> {
    let uri = 'edit_push';
    let host =
      this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' + request.token +'", "id": "' + request.id + '"}';
    //let host = this.util.obterHostDaApi() + 'edit_push';
    //host = host + '?token=' + request.token + '&id=' +request.id;
    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  pagar(request: any): Promise<HttpResponse> {
    let host = 'https://104.236.48.169/faturas-cobranca-simples-integracao';

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };

    return this.http
      .post<HttpResponse>(host, request, { headers: options.headers })
      .toPromise();
  }

  pagarNovo(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostDaApi() + 'pagamentos';
    host =
      host +
      '?email=' +
      request.email +
      '&placa=' +
      request.placa +
      '&valor=' +
      request.valor;

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };
    return this.http.post<HttpResponse>(host, options).toPromise();
  }

  indicar(request: any): Promise<HttpResponse> {
    // indicado eh 2
    //indicador eh 1
    let uri = 'sendrecommends';
    let host = this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte';
    host =
      host +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host = host + '&' + 'dados=' + '{"token":"' + request.token + '",';
    host = host + '"nome_indicador":"' + request.nome1 + '",';
    host = host + '"email_indicador":"' + request.email1 + '",';
    host = host + '"cpf_indicador":"' + request.cpf1 + '",';
    host = host + '"telefone_indicador":"' + request.telefone1 + '",';
    host = host + '"cidade_indicador":"' + request.cidade1 + '",';
    host = host + '"estado_indicador":"' + request.estado1 + '",';
    host = host + '"observacao_indicador":"' + request.observacao1 + '",';
    host = host + '"nome_recomendado":"' + request.nome2 + '",';
    host = host + '"email_recomendado":"' + request.email2 + '",';
    host = host + '"telefone_recomendado":"' + request.telefone2 + '",';
    host = host + '"cidade_recomendado":"' + request.cidade2 + '",';
    host = host + '"estado_recomendado":"' + request.estado2 + '",';
    host = host + '"observacao":"' + request.observacao2 + '"}';
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  async getFaturas(): Promise<HttpResponse> {
    let host = this.util.getIpMydas();

    let cpf = JSON.parse(localStorage.getItem('dados_cliente')).cpf;
    if (cpf === '') {
      cpf = '0';
    }
    let id_mydas = JSON.parse(localStorage.getItem('dados_empresa')).id_mydas;
    host = `${host}/faturas-cliente-ws/${id_mydas}/${cpf}`;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return await this.http.get<HttpResponse>(host, options).toPromise();
  }

  async getRegistro(fatura): Promise<HttpResponse> {
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    let url =
      'https://www.painel-mydas.com.br/transacao-boleto/' + fatura + '/0/1';
    return await this.http.get<HttpResponse>(url, options).toPromise();
  }

  atualizarPush(request: any): Promise<HttpResponse> {
    let uri = 'att_push';
    let host =
      this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '" , "player": "' +
      request.player +
      '", "aparelho": "' +
      request.aparelho +
      '", "plataforma": "' +
      request.plataforma +
      '" }';
    //let host = this.util.obterHostDaApi() + 'rastreamento/valida';
    //host = host + '?token=' + request.token + '&imei=' +request.imei;
    console.log(host);
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  testarPush(request: any): Promise<HttpResponse> {
    let uri = 'send_push_app';
    let host =
      this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';
    //let host = this.util.obterHostDaApi() + 'rastreamento/valida';
    //host = host + '?token=' + request.token + '&imei=' +request.imei;

    console.log(host);

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  apagarLogPush(externalId: any): Promise<HttpResponse> {
    let uri = 'deletar_log';
    let host =
      this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host = host + '&' + 'dados=' + '{"token": "' + externalId + '" }';

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  validarAparelho(request: any): Promise<HttpResponse> {
    let uri = 'rastreamento/valida';
    let host =
      this.util.obterHostPadraoDaApi() + '/api/apis_aplicativo_ponte' + '?';
    host =
      host +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '"}';
    //let host = this.util.obterHostDaApi() + 'rastreamento/valida';
    //host = host + '?token=' + request.token + '&imei=' +request.imei;

    let options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded;')
        .append('Content-Type', 'applciation/json'),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  updateNecessario() {
    let update = false;

    if (
      localStorage.getItem('favicon') == null ||
      localStorage.getItem('favicon') === undefined ||
      localStorage.getItem('favicon') === 'null'
    ) {
      update = true;
    }

    return update;
  }

  atualizaPosicaoAparelho() {
    let latAparelho;
    let lngAparelho;
    if (localStorage.getItem('want_locale') === 'true') {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition((position) => {
            latAparelho = position.coords.latitude;
            lngAparelho = position.coords.longitude;
          });
        } catch {}
      }
    }

    setTimeout(() => {
      try {
        if (latAparelho !== undefined) {
          let uri = 'rastreamento/att';
          let imei = this.imei;
          let host =
            this.util.obterHostPadraoDaApi() +
            '/api/apis_aplicativo_ponte' +
            '?' +
            'uri=' +
            uri +
            '&' +
            'ip=';
          host =
            host +
            this.util.obterHostDaApi().replace(':8890', '') +
            '&method=POST';
          host =
            host +
            '&' +
            'dados=' +
            '{"latitude": "' +
            latAparelho +
            '" , "longitude":"' +
            lngAparelho +
            '", "imei": "' +
            imei +
            '"}';

          //let host = this.util.obterHostDaApi() + 'rastreamento/att';
          //host = host + '?latitude=' + latAparelho + '&longitude=' +lngAparelho+ '&imei=' +this.util.getDeviceID();

          let options = {
            headers: new HttpHeaders()
              .append('Content-Type', 'application/x-www-form-urlencoded;')
              .append('Content-Type', 'applciation/json'),
          };
          this.http
            .get<HttpResponse>(host, options)
            .toPromise()
            .then((data) => {});
        }
      } catch (error) {
        console.log(error);
      }
    }, 2000); //coloca um time para pega a localização
  }

  getPontos(request: any): Promise<HttpResponse> {
    let uri = 'get_pontos';
    let host = `${this.util.obterHostPadraoDaApi()}/api/apis_aplicativo_ponte?uri=${uri}`;
    host =
      host +
      `&ip=${this.util
        .obterHostDaApi()
        .replace(':8890', '')}&method=GET&dados={"token":"${request.token}"}`;
    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  produtosTroca(request: any): Promise<HttpResponse> {
    let uri = 'produtos_troca';
    let host = `${this.util.obterHostPadraoDaApi()}/api/apis_aplicativo_ponte?uri=${uri}`;
    host =
      host +
      `&ip=${this.util
        .obterHostDaApi()
        .replace(':8890', '')}&method=GET&dados={'token':'${request.token}'}`;
    // host = 'http://localhost:8890/produtos_troca?token='+request.token

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  confirmaTroca(request: any) {
    // requisição remota (falta testar 09/2022)
    let uri = 'trocar_produtos';
    let host = `${this.util.obterHostPadraoDaApi()}/api/apis_aplicativo_ponte`;
    host =
      host +
      `?uri=${uri}&ip=${this.util
        .obterHostDaApi()
        .replace(':8890', '')}&dados=${JSON.stringify(request)}&method=POST`;

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();

    // requisição local
    // const formData = new FormData();

    // Object.keys(request).forEach(key => {
    //   if(key !== 'token') formData.append(key, request[key])
    // })

    // let host = `http://localhost:8890/trocar_produtos?token=${request.token}`

    // return this.http.post<HttpResponse>(host, formData).toPromise();
  }

  getHistory(request: any): Promise<HttpResponse> {
    let uri = 'historico_trocas';
    let host = `${this.util.obterHostPadraoDaApi()}/api/apis_aplicativo_ponte?uri=${uri}`;
    host =
      host +
      `&ip=${this.util
        .obterHostDaApi()
        .replace(':8890', '')}&method=GET&dados={'token':'${request.token}'}`;
    // host = 'http://localhost:8890/historico_trocas?token='+request.token

    let options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;',
      ),
    };
    return this.http.get<HttpResponse>(host, options).toPromise();
  }
}
